<template>
  <section>
    <div class="card">
      <header class="card-header">
        <p class="card-header-title">
            Fleet Defaults for this Route
        </p>
      </header>
      <div class="list is-hoverable">
        <a class="list-item" v-for="(fleet, index) in route.fleet_defaults" :key="fleet.id">
          <div class="columns">
            <div class="column is-2">
              <strong>
              {{ fleet.name }}
              </strong>
            </div>
            <div class="column is-3">
              <p class="control">
                <b-switch
                    :ref="'taxiInSwitch'"
                    :value="fleet.taxi_in != null"
                    @input="onToggleTaxiInSwitch(index)"
                    type="is-success">
                    Set Default Taxi In
                </b-switch>
              </p>
            </div>
            <div class="column is-2">
              <p class="control" style="width:100%" v-if="fleet.taxi_in != null">
                <b-field :type="{'is-danger' : fleet.taxi_in != null && fleet.taxi_in < 0}" style="min-width:100%;"> 
                  <b-input type="number" v-model="fleet.taxi_in"  :required="fleet.taxi_in != null" min="0" step="100"></b-input>
                </b-field>
              </p>  
            </div>
            <div class="column is-3">
              <p class="control">
                <b-switch
                    :ref="'taxiOutSwitch'"
                    :value="fleet.taxi_out != null"
                    @input="onToggleTaxiOutSwitch(index)"
                    type="is-success">
                    Set Default Taxi Out
                </b-switch>
              </p>
            </div>
            <div class="column is-2">
              <p class="control" style="width:100%" v-if="fleet.taxi_out != null">
                <b-field :type="{'is-danger' : fleet.taxi_out != null && fleet.taxi_out < 0}" style="min-width:100%;"> 
                  <b-input type="number" v-model="fleet.taxi_out"  :required="fleet.taxi_out != null" min="0" step="100"></b-input>
                </b-field>
              </p>  
            </div>
          </div>
        </a>
      </div> 
    </div>
  </section>
</template>

<script>
import FleetsService from "../../services/fleets.service";
import { EventBus } from '../../event-bus.js';

export default {
  name: "FleetsConfigurator",
  props:[
      'route'
  ],
  data() {
    return {
      fleetsService: new FleetsService(),
      fleets: []
    };
  },
  computed: {
  },
  methods: {
    setDefaults () {
      this.fleetsService.findAll().then(
        ({data}) => {
          this.fleets = data.fleets;
          this.route.fleet_defaults = [];
          this.fleets.forEach(fleet => {
            let taxiIn = null;
            let taxiOut = null;
            if  (this.route.fleetDefaults) {
              let fleetDefaultIndex = this.route.fleetDefaults.findIndex(x => x.fleet_id === fleet.id);
              if (fleetDefaultIndex != -1) {
                taxiIn = this.route.fleetDefaults[fleetDefaultIndex].taxi_in;
                taxiOut = this.route.fleetDefaults[fleetDefaultIndex].taxi_out; 
              }
            }
            this.route.fleet_defaults.push({fleet_id: fleet.id, name: fleet.name, route_id: this.route.id, taxi_in: taxiIn, taxi_out: taxiOut});
          });
        }
      );
    },
    onToggleTaxiInSwitch (index) {
      if (this.$refs['taxiInSwitch'][index].computedValue == false) {
        this.route.fleet_defaults[index].taxi_in = null;
      } else {
        this.route.fleet_defaults[index].taxi_in = 0;
      }
    },
    onToggleTaxiOutSwitch (index) {
      if (this.$refs['taxiOutSwitch'][index].computedValue == false) {
        this.route.fleet_defaults[index].taxi_out = null;
      } else {
        this.route.fleet_defaults[index].taxi_out = 0;
      }
    }
  },
  watch: {
  },
  created() {
    EventBus.$on('RouteLoaded', this.setDefaults);
  },
  components: {
  }
};
</script>
<style>
</style>
