<template>
  <div>
    <vue-headful :title="`FLPN Aviasolutions - ${pageTitle}`"></vue-headful>
    <h1 class="title">{{ pageTitle }}</h1>
    <contenedor>
      <b-notification
          type="is-warning"
          has-icon
          aria-close-label="Close notification"
          role="alert"
          v-if="hasDuplicateRoutes"
      >
        There are routes with the same origin and destination.
        <a
            href="javascript:;"
            class="has-text-danger"
            @click="seeDuplicateRoutes"
        >See Routes</a>
      </b-notification>
      <div class="columns">
        <div class="column is-one-third">
          <div class="box">
            <h1 class="title is-4">Departure</h1>
            <div class="field">
              <label class="label">Airport</label>

              <div class="field">
                <b-notification
                    v-if="departureNonViable"
                    type="is-danger"
                    aria-close-label="Close notification"
                    role="alert"
                    v-on:close="departureNonViable = false; clearDeparture()">
                  Original Departure was: {{ route.departure_icao }}
                </b-notification>
                <DisabledClearable
                    v-else-if="route.departure"
                    v-on:clearValue="clearDeparture"
                    v-bind:inputValue="route.departure.icao"
                ></DisabledClearable>

                <AirportSelect
                    v-else
                    v-on:selectedOption="selectDeparture"
                    value="route.departure_icao"
                    ref="departure"
                ></AirportSelect>
              </div>
            </div>
            <b-field label="SID">
              <b-notification
                  v-if="sidNonViable"
                  type="is-danger"
                  aria-close-label="Close notification"
                  role="alert"
                  v-on:close="sidNonViable = false; clearSid()">
                Original SID value was: {{ route.sid_name }}
              </b-notification>
              <DisabledClearable
                  v-else-if="route.sid"
                  v-on:clearValue="clearSid"
                  v-bind:inputValue="route.sid.name"
              ></DisabledClearable>
              <b-autocomplete
                  v-else
                  :data="filteredSids"
                  v-model="inputSid"
                  placeholder="SID"
                  field="name"
                  @select="selectSid"
                  :open-on-focus="true"
                  :loading="isFetchingSids"
                  :disabled="!route.hasDeparture"
              >
                <template slot="empty">No results found</template>
              </b-autocomplete>
            </b-field>
            <b-field label="Transition">
              <b-notification
                  v-if="sidTransitionNonViable"
                  type="is-danger"
                  aria-close-label="Close notification"
                  role="alert"
                  v-on:close="sidTransitionNonViable = false; clearSidTransition()">
                Original SID Transition was: {{ route.sid_transition_name }}
              </b-notification>
              <DisabledClearable
                  v-else-if="route.sidTransition"
                  v-on:clearValue="clearSidTransition"
                  v-bind:inputValue="route.sidTransition"
              ></DisabledClearable>
              <b-autocomplete
                  v-else
                  v-model="inputSidTransition"
                  :data="filteredSidTransitions"
                  placeholder="ALL"
                  field="name"
                  @select="selectSidTransition"
                  :open-on-focus="true"
                  :loading="isFetchingSidTransitions"
                  :disabled="!route.hasSid"
              >
                <template slot="empty">No results found</template>
              </b-autocomplete>
            </b-field>
            <b-taglist v-if="route.sidWaypoints.length > 0">
              <b-tag v-for="waypoint in route.sidWaypoints" :key="waypoint.waypoint_id">{{waypoint.name}}</b-tag>
            </b-taglist>
          </div>
          <div class="box">
            <h1 class="title is-4">Destination</h1>
            <div class="field">
              <label class="label">Airport</label>
              <div class="control">
                <b-notification
                    v-if="destinationNonViable"
                    type="is-danger"
                    aria-close-label="Close notification"
                    role="alert"
                    v-on:close="destinationNonViable = false; clearDestination()">
                  Original Destination was: {{ route.destination_icao }}
                </b-notification>
                <DisabledClearable
                    v-else-if="route.destination"
                    v-on:clearValue="clearDestination"
                    v-bind:inputValue="route.destination.icao"
                ></DisabledClearable>
                <AirportSelect v-else v-on:selectedOption="selectDestination"></AirportSelect>
              </div>
            </div>
            <b-field label="STAR">
              <b-notification
                  v-if="starNonViable"
                  type="is-danger"
                  aria-close-label="Close notification"
                  role="alert"
                  v-on:close="starNonViable = false; clearStar()">
                Original STAR was: {{ route.star_name }}
              </b-notification>
              <DisabledClearable
                  v-else-if="route.star"
                  v-on:clearValue="clearStar"
                  v-bind:inputValue="route.star.name"
              ></DisabledClearable>

              <b-autocomplete
                  v-else
                  :data="filteredStars"
                  v-model="inputStar"
                  placeholder="STAR"
                  field="name"
                  @select="selectStar"
                  :open-on-focus="true"
                  :loading="isFetchingStars"
                  :disabled="!route.hasDestination"
              >
                <template slot="empty">No results found</template>
              </b-autocomplete>
            </b-field>
            <b-field label="Transition">
              <b-notification
                  v-if="starTransitionNonViable"
                  type="is-danger"
                  aria-close-label="Close notification"
                  role="alert"
                  v-on:close="starTransitionNonViable = false; clearStarTransition()">
                Original STAR Transition was: {{ route.star_transition_name }}
              </b-notification>
              <DisabledClearable
                  v-else-if="route.starTransition"
                  v-on:clearValue="clearStarTransition"
                  v-bind:inputValue="route.starTransition"
              ></DisabledClearable>
              <b-autocomplete
                  v-else
                  v-model="inputStarTransition"
                  :data="filteredStarTransitions"
                  placeholder="ALL"
                  field="name"
                  :open-on-focus="true"
                  :loading="isFetchingStarTransitions"
                  :disabled="!route.hasStar"
              >
                <template slot="empty">No results found</template>
              </b-autocomplete>
            </b-field>
            <b-taglist v-if="route.starWaypoints.length > 0">
              <b-tag v-for="waypoint in route.starWaypoints" :key="waypoint.waypoint_id">{{waypoint.name}}</b-tag>
            </b-taglist>
            <b-field label="IAC">
              <b-notification
                  v-if="iacNonViable"
                  type="is-danger"
                  aria-close-label="Close notification"
                  role="alert"
                  v-on:close="iacNonViable = false; clearIac()">
                Original IAC Name was: {{ route.iac_full_name }}
              </b-notification>
              <DisabledClearable
                  v-else-if="route.iac"
                  v-on:clearValue="clearIac"
                  v-bind:inputValue="route.iac.full_name"
              ></DisabledClearable>
              <b-autocomplete
                  v-else
                  :data="filteredIacs"
                  v-model="inputIac"
                  placeholder="IAC"
                  field="full_name"
                  @select="selectIac"
                  :open-on-focus="true"
                  :loading="isFetchingIacs"
                  :disabled="!route.hasStar && !route.hasStarTransition"
              >
                <template slot="empty">No results found</template>
              </b-autocomplete>
            </b-field>
          </div>
          <div class="block">
            <b-radio v-model="route.is_public" native-value="true">Public</b-radio>
            <b-radio v-model="route.is_public" native-value="false">Private</b-radio>
          </div>
        </div>
        <div class="column">
          <div class="box">
            <table class="table is-fullwidth">
              <thead>
              <tr>
                <th>DCT</th>
                <th>Waypoint</th>
                <th>Airway</th>
              </tr>
              </thead>
              <tbody>
              <RouteLeg
                  v-for="leg in route.legs"
                  :key="leg.index"
                  v-bind:leg="leg"
                  v-bind:departure="route.departure"
                  v-bind:legs="route.legs"
                  v-on:addLeg="addLeg"
              ></RouteLeg>
              </tbody>
            </table>
          </div>
          <a v-if="!this.checkNonViableItems() && route.is_viable" class="button is-primary" v-on:click="saveRoute">Save
            Route</a>
          <b-notification
              v-if="!route.is_viable && !this.checkNonViableItems()"
              type="is-warning"
              :closable="false"
              role="alert">
            <a class="button is-primary" v-on:click="saveRoute">
              <i class="fa fa-exclamation-triangle"></i>&nbsp;&nbsp;
              Fix this Route
            </a>
          </b-notification>
          <b-notification
              v-if="this.checkNonViableItems()"
              type="is-warning"
              has-icon
              :closable="false"
              role="alert">
            This route is non-viable and can't be updated until you fix all problems marked in red
          </b-notification>
        </div>
      </div>
      <div class="column is-12">
        <fleetsConfigurator :route="route"></fleetsConfigurator>
      </div>
    </contenedor>
  </div>
</template>

<script>
import AirportSelect from "../AirportSelect.vue";
import DisabledClearable from "../DisabledClearable.vue";
import RouteDuplicatesModal from "./RouteDuplicatesModal";
import FleetsConfigurator from "./FleetsConfigurator";
import RouteLeg from "./RouteLeg.vue";
import RoutesService from "../../services/routes.service";
import AirwaysService from "../../services/airways.service";
import TerminalsService from "../../services/terminals.service";
import Route from "../../models/route/route.model";
import Leg from "../../models/route/routeLeg.model";
import {EventBus} from '../../event-bus.js';
import Contenedor from "@/components/Contenedor";

export default {
  name: "RouteCreator",
  props: {
    msg: String
  },
  data() {
    return {
      pageTitle: "Create Route",
      routeService: new RoutesService(),
      airwaysService: new AirwaysService(),
      terminalsService: new TerminalsService(),
      route: new Route({is_viable: true}),
      sids: [],
      inputSid: "",
      inputSidTransition: "",
      sidTransitions: [],
      stars: [],
      inputStar: "",
      inputStarTransition: "",
      inputIac: "",
      starTransitions: [],
      iacs: [],
      isFetchingSids: false,
      isFetchingStars: false,
      isFetchingSidTransitions: false,
      isFetchingStarTransitions: false,
      isFetchingIacs: false,
      duplicateRoutes: [],
      departureNonViable: false,
      sidNonViable: false,
      sidTransitionNonViable: false,
      destinationNonViable: false,
      starNonViable: false,
      starTransitionNonViable: false,
      iacNonViable: false,
      hasNonViableItems: false
    };
  },
  computed: {
    filteredSids() {
      let filtrados = this.sids.filter(option => {
        return (
            option.name
                .toString()
                .toUpperCase()
                .indexOf(this.inputSid.toUpperCase()) >= 0
        );
      });
      if (filtrados.length === 1 && this.inputSid.length > 0) {
        this.selectSid(filtrados[0]);
      }
      return filtrados;
    },
    filteredSidTransitions() {
      let filtrados = this.sidTransitions.filter(option => {
        return (
            option
                .toString()
                .toUpperCase()
                .indexOf(this.inputSidTransition.toUpperCase()) >= 0
        );
      });
      if (filtrados.length === 1) {
        this.selectSidTransition(filtrados[0]);
      }
      return filtrados;
    },
    filteredStars() {
      let filtrados = this.stars.filter(option => {
        return (
            option.name
                .toString()
                .toUpperCase()
                .indexOf(this.inputStar.toUpperCase()) >= 0
        );
      });
      if (filtrados.length === 1 && this.inputStar.length > 0) {
        this.selectStar(filtrados[0]);
      }
      return filtrados;
    },
    filteredStarTransitions() {
      let filtrados = this.starTransitions.filter(option => {
        return (
            option
                .toString()
                .toUpperCase()
                .indexOf(this.inputStarTransition.toUpperCase()) >= 0
        );
      });
      if (filtrados.length === 1) {
        this.selectStarTransition(filtrados[0]);
      }
      return filtrados;
    },
    filteredIacs() {
      let filtrados = this.iacs.filter(option => {
        return (
            option.full_name
                .toString()
                .toUpperCase()
                .indexOf(this.inputIac.toUpperCase()) >= 0
        );
      });
      if (filtrados.length === 1) {
        this.selectIac(filtrados[0]);
      }
      return filtrados;
    },
    // Validad el ORIGEN/DESTINO
    validateDuplicity() {
      return this.route.departure !== null && this.route.destination !== null;
    },
    hasDuplicateRoutes() {
      return this.duplicateRoutes && this.duplicateRoutes.length > 0;
    }
  },
  methods: {
    reset: function () {
      this.route = new Route({is_viable: true});
      this.inputSid = this.route.sid;
      this.inputStar = this.route.star;
      this.inputIac = this.route.iac;
    },
    selectDeparture: function (departure) {
      this.route.departure = departure;
      this.isFetchingSids = true;
      this.$http
          .get(
              `${process.env.VUE_APP_HOST}/airports/${this.route.departure.id}/sids`
          )
          .then(
              response => {
                this.sids = response.data.terminals;
                if (this.sids.length === 1) {
                  this.selectSid(this.sids[0]);
                }
                this.isFetchingSids = false;
              },
              () => {
                this.isFetchingSids = false;
              }
          );
    },
    clearDeparture: function () {
      this.route.clearDeparture();
    },
    clearSid: function () {
      this.route.clearSid();
    },
    clearSidTransition: function () {
      this.route.clearSidTransition();
    },
    clearDestination: function () {
      this.route.clearDestination();
    },
    clearStar: function () {
      this.route.clearStar();
    },
    clearStarTransition: function () {
      this.route.clearStarTransition();
    },
    clearIac() {
      this.route.clearIac();
    },
    selectSid: function (sid) {
      this.route.sid = sid;
      this.isFetchingSidTransitions = true;
      this.$http
          .get(
              `${process.env.VUE_APP_HOST}/terminals/${this.route.sid.id}/transitions`
          )
          .then(
              response => {
                this.sidTransitions = response.data.transitions;
                this.isFetchingSidTransitions = false;
              },
              response => {
                this.isFetchingSidTransitions = false;
              }
          );
    },
    selectSidTransition: function (transition) {
      this.route.sidTransition = transition;
      this.routeService.sidWaypoints({sid_id: this.route.sid.id, sid_transition: transition}).then(({data}) => {
        this.route.sidWaypoints = data.data;
        const lastWaypoint = this.route.sidWaypoints[this.route.sidWaypoints.length - 1];
        // eslint-disable-next-line no-console

        console.log('waypoint_id: ', lastWaypoint.waypoint_id);
        this.$http
            .get(`${process.env.VUE_APP_HOST}/waypoints/${lastWaypoint.waypoint_id}/airways`)
            .then(
                response => {
                  let airways = response.data.airways;
                  this.route.legs = [];
                  this.route.legs.push(
                      new Leg({
                        kind: "SID",
                        airways: airways,
                        waypoint: lastWaypoint,
                        waypoint_ident: lastWaypoint.name,
                        index: 0
                      })
                  );
                  this.route.legs.push(
                      new Leg({
                        kind: "DCT",
                        waypoints: [],
                        airways: [],
                        waypoint: null,
                        index: 1
                      })
                  );
                },
                response => {
                  this.$buefy.toast.open("Airways Not Found");
                }
            );
      });
    },
    selectDestination: function (destination) {
      this.isFetchingStars = true;
      this.route.destination = destination;
      this.$http
          .get(
              `${process.env.VUE_APP_HOST}/airports/${this.route.destination.id}/stars`
          )
          .then(
              response => {
                this.stars = response.data.terminals;
                this.isFetchingStars = false;
              },
              respose => {
                this.isFetchingStars = false;
              }
          );
    },
    selectStar: function (star) {
      this.isFetchingStarTransitions = true;
      this.route.star = star;
      this.terminalsService.transitions(this.route.star.id).then(
          ({data}) => {
            this.starTransitions = data.transitions;
            this.isFetchingStarTransitions = false;
          },
          () => {
            this.isFetchingStarTransitions = false;
          }
      );
    },
    selectStarTransition: function (transition) {
      this.route.starTransition = transition;
      const params = {
        star_id: this.route.star.id,
        star_transition: transition
      };
      this.isFetchingIacs = true;
      this.routeService.starWaypoints(params).then(({data}) => {
        this.route.starWaypoints = data.data;
      });
      this.terminalsService.iacs(params).then(
          ({data}) => {
            this.iacs = data.iacs;
            this.isFetchingIacs = false;
          },
          () => {
            this.isFetchingIacs = false;
          }
      );
    },
    selectIac(iac) {
      this.route.iac = iac;
    },
    checkExistingRoute() {
      // Validad el ORIGEN/DESTINO
      if (this.validateDuplicity) {
        const params = {
          departure: {
            departure_id: this.route.departure.id
          },
          destination: {
            destination_id: this.route.destination.id
          }
        };
        if (this.route.exists) {
          params.route_id = this.route.id;
        }
        if (this.route.sid) {
          params.departure.sid_id = this.route.sid.id;
        }
        if (this.route.sidTransition) {
          params.departure.sid_transition = this.route.sidTransition;
        }
        if (this.route.star) {
          params.destination.star_id = this.route.star.id;
        }
        if (this.route.starTransition) {
          params.destination.star_transition = this.route.starTransition;
        }
        if (this.route.iac) {
          params.destination.iac_id = this.route.iac.id;
        }
        this.routeService.checkExisting(params).then(
            ({data}) => {
              this.duplicateRoutes = data.routes;
            },
            () => {
            }
        );
      }
    },
    seeDuplicateRoutes() {
      this.$buefy.modal.open({
        parent: this,
        component: RouteDuplicatesModal,
        props: {
          routes: this.duplicateRoutes
        },
        canCancel: false,
        hasModalCard: true
      });
    },
    addLeg: function (data) {
      console.log('addLeg');
      let index = data.index;
      let airway = data.airway;
      let leftWaypoint = data.leftWaypoint;
      if (airway) {
        this.airwaysService
            .waypointsSince(airway.id, leftWaypoint.id)
            .then(response => {
              let waypoints = response.data.waypoints;
              let location = index + 1;
              let leg = new Leg({
                kind: "WP",
                waypoints: waypoints,
                airways: [],
                waypoint: null,
                index: location
              });
              console.log("route.legs:", this.route.legs.length);
              console.log("location:", location);
              this.route.setLeg(location, leg);
            });
      } else {
        let location = index + 1;
        let leg = new Leg({
          kind: "DCT",
          waypoints: [],
          airways: [],
          waypoint: null,
          index: location
        });
        console.log("route.legs:", this.route.legs.length);
        console.log("location:", location);
        this.route.setLeg(location, leg);
      }
    },
    saveRoute: function () {
      const loadingComponent = this.$buefy.loading.open({container: null})
      if (this.checkNonViableItems()) {
        loadingComponent.close();
        return;
      }
      console.log(this.route.legs)
      this.routeService.save(this.route).then(
          response => {
            loadingComponent.close();
            this.$router.push(`/routes/${response.data.route.id}`);
          },
          fail => {
            loadingComponent.close();
          }
      );
    },
    checkInvalidFields: function () {
      this.sidNonViable = (
          !this.route.is_viable &&
          !this.route.sid &&
          Boolean(this.route.sid_name)
      );
      this.sidTransitionNonViable = (
          !this.route.is_viable &&
          !this.route.sidTransition &&
          Boolean(this.route.sid_transition_name)
      );
      this.destinationNonViable = (
          !this.route.is_viable &&
          !this.route.destination &&
          Boolean(this.route.destination_icao)
      );
      this.starNonViable = (
          !this.route.is_viable &&
          !this.route.star &&
          Boolean(this.route.star_name)
      );
      this.starTransitionNonViable = (
          !this.route.is_viable &&
          !this.route.starTransition &&
          Boolean(this.route.star_transition_name)
      );
      this.iacNonViable = (
          !this.route.is_viable &&
          !this.route.iac_id &&
          Boolean(this.route.iac_full_name)
      );
      this.departureNonViable = (
          !this.route.is_viable &&
          !this.route.departure &&
          Boolean(this.route.departure_icao)
      );
    },
    checkNonViableItems: function () {
      var nonViableLegs = false;
      for (let i = 0; i < this.route.legs.length; i++) {
        if (this.route.legs[i].nonViable) {
          nonViableLegs = true;
          break;
        }
      }
      this.hasNonViableItems = (
          this.sidNonViable ||
          this.starNonViable ||
          this.sidTransitionNonViable ||
          this.starTransitionNonViable ||
          this.departureNonViable ||
          this.destinationNonViable ||
          this.iacNonViable ||
          nonViableLegs
      );
      return this.hasNonViableItems;
    }
  },
  watch: {
    $route(to) {
      if (to.path === "/routes/create") {
        this.reset();
      }
    }
  },
  mounted: function () {
    if (this.$route.params.id) {
      this.pageTitle = `Edit Route ${this.$route.params.id}`;
      const loadingComponent = this.$buefy.loading.open({container: null})
      this.routeService.edit(this.$route.params.id).then(
          ({data}) => {
            this.route.fill(data.data);
            this.checkInvalidFields();
            this.checkNonViableItems();
            EventBus.$emit(
                'RouteLoaded',
                {}
            );
            loadingComponent.close();
          },
          () => {
            loadingComponent.close();
            this.$buefy.toast.open({
              message: "Route not found.",
              type: "is-danger"
            });
          }
      );
    } else {
      EventBus.$emit(
          'RouteLoaded',
          {}
      );
    }
    // WATCHING: Multiples Campos
    this.$watch(
        () => ({
          departure: this.route.departure,
          sid: this.route.sid,
          sidTransition: this.route.sidTransition,
          destination: this.route.destination,
          star: this.route.star,
          starTransition: this.route.starTransition,
          iac: this.route.iac
        }),
        () => {
          this.checkExistingRoute();
        }
    );
  },
  components: {
    Contenedor,
    AirportSelect,
    RouteLeg,
    DisabledClearable,
    RouteDuplicatesModal,
    FleetsConfigurator
  }
};
</script>
<style>
.notification {
  background-color: whitesmoke;
  border-radius: 4px;
  padding: 0.3rem 0.2rem 0.3rem 0.4rem;
  position: relative;
}
</style>
