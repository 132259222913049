<template>
  <tr>
    <td>
      <a class="button" v-if="leg.isConvertible" @click="toogleDCT">{{leg.kind}}</a>

      <label class="button" disabled v-else>{{leg.kind}}</label>
    </td>
    <td>
      <b-field v-if="leg.isDCT">
        <b-notification
          v-if="waypointNonViable"
            type="is-danger"
            aria-close-label="Close notification"
            role="alert"
            v-on:close="waypointNonViable = false; leg.waypoint_ident = null; clearWaypoint(); checkLegValidity()">
            Original Waypoint Ident was: {{leg.waypoint_ident}}
        </b-notification>
        <DisabledClearable
          v-else-if="leg.hasWaypoint"
          v-on:clearValue="clearWaypoint"
          v-bind:inputValue="leg.waypoint_ident"
        ></DisabledClearable>
        <b-field v-else>
          <b-input placeholder="Search..." type="search" v-model="waypoint_ident" expanded></b-input>
          <p class="control">
            <button class="button is-primary" v-on:click="searchWaypoint">
              <b-icon pack="fas" icon="search" size="is-small"></b-icon>
            </button>
          </p>
        </b-field>
      </b-field>

      <div v-else-if="!leg.isSid">
        <b-notification
          v-if="waypointNonViable"
            type="is-danger"
            aria-close-label="Close notification"
            role="alert"
            v-on:close="waypointNonViable = false; leg.airway_ident = null; clearAirway(); checkLegValidity()">
            Original Waypoint Ident was: {{leg.waypoint_ident}}
        </b-notification>

        <DisabledClearable
          v-else-if="leg.hasWaypoint"
          v-on:clearValue="clearWaypoint"
          v-bind:inputValue="leg.waypoint.ident"
        ></DisabledClearable>
        <b-autocomplete
          v-else
          :data="filteredWaypoints"
          field="ident"
          @select="selectWaypoint"
          :open-on-focus="true"
        >
          <template slot="empty">No results found</template>
        </b-autocomplete>
      </div>

      <b-input v-else disabled :value="leg.waypoint_ident"></b-input>
    </td>
    <td>
      <DisabledClearable
        v-if="leg.airway"
        v-on:clearValue="clearAirway"
        v-bind:inputValue="leg.airway.ident"
      ></DisabledClearable>
      <b-autocomplete
        v-else
        :disabled="leg.hasWaypointOfClient"
        :data="filteredAirways"
        v-model="inputAirway"
        field="ident"
        @select="selectAirway"
        :open-on-focus="true"
        :loading="isFetchingAirways"
      >
        <template slot="empty">No results found</template>
      </b-autocomplete>
      <b-notification
          v-if="!leg.airway && leg.ayrway_ident"
          type="is-danger"
          aria-close-label="Close notification"
          role="alert">
          Original Airway Ident was: {{leg.airway_ident}}
      </b-notification>
    </td>
  </tr>
</template>

<script>
import DisabledClearable from "../DisabledClearable.vue";
import WaypointService from "../../services/waypoint.service";

export default {
  name: "RouteLeg",
  props: ["departure", "legs", "leg"],
  data() {
    return {
      inputAirway: "",
      waypoint_ident: "",
      waypointNonViable: false,
      airWayNonViable: false,
      legNonViable: false,
      isFetchingAirways: false,
      waypointService: new WaypointService()
    };
  },
  mounted: function() {
    this.checkInvalidFields();
  },
  computed: {
    filteredAirways() {
      if (!this.leg.airways) {
        return [];
      }
      return this.leg.airways.filter(option => {
        return (
          option.ident
            .toString()
            .toUpperCase()
            .indexOf(this.inputAirway.toUpperCase()) >= 0
        );
      });
    },
    filteredWaypoints() {
      if (!this.leg.waypoints) {
        return [];
      }
      let filtrados = this.leg.waypoints.filter(option => {
        return (
          option.ident
            .toString()
            .toUpperCase()
            .indexOf(this.waypoint_ident.toUpperCase()) >= 0
        );
      });
      if (filtrados.length == 1) {
        this.selectWaypoint(filtrados[0]);
      }
      return filtrados;
    }
  },
  methods: {
    toogleDCT: function() {
      if (this.leg.isDCT) {
        this.leg.kind = "WP";
      } else {
        this.leg.kind = "DCT";
      }
    },
    selectWaypoint: function(waypoint) {
      this.leg.waypoint = waypoint;
      this.loadAirways();
      this.$emit("addLeg", { index: this.leg.index });
    },
    searchWaypoint: function() {
      const params = {};
      if (this.leg.isFirst) {
        if (this.departure == null) {
          this.$buefy.toast.open({
            message: "Airport not selected!!!",
            type: "is-danger"
          });
          return false;
        }
        params.latitude = this.departure.latitude;
        params.longitude = this.departure.longitude;
      } else {
        const previousLeg = this.legs[this.leg.index - 1];
        const waypoint = previousLeg.waypoint;
        params.latitude = waypoint.latitude;
        params.longitude = waypoint.longitude;
      }
      const loadingComponent = this.$buefy.loading.open({container: null})
      this.waypointService.search(this.waypoint_ident, params).then(
        ({data}) => {
          this.leg.waypoint = data.waypoint;
          this.leg.waypoint_ident = data.waypoint.ident
          if (data.waypoint.id) {
            this.loadAirways();
          }
          this.$emit("addLeg", { index: this.leg.index });
          loadingComponent.close();
        },
        response => {
          loadingComponent.close();
          this.$buefy.toast.open(response.data.errors);
        }
      );
    },
    clearWaypoint: function() {
      this.leg.waypoint = null;
      this.leg.airway = null;
      this.leg.airways = null;
      this.inputAirway = "";
      this.waypoint_ident = "";
    },
    loadAirways: function() {
      this.isFetchingAirways = true;
      this.waypointService.airways(this.leg.waypoint.id).then(
        response => {
          this.leg.airways = response.data.airways;
          this.isFetchingAirways = false;
        },
        response => {
          this.$buefy.toast.open("Airways Not Found");
          this.isFetchingAirways = false;
        }
      );
    },
    selectAirway: function(data) {
      this.leg.airway = data;
      console.log("selectAirway", { airway: data, index: this.leg.index });
      this.$emit("addLeg", {
        airway: data,
        index: this.leg.index,
        leftWaypoint: this.leg.waypoint
      });
    },
    clearAirway: function() {
      this.leg.airway = null;
      this.$emit("addLeg", { airway: null, index: this.leg.index });
    },
    checkInvalidFields: function() {
      // this.waypointNonViable = ((!Boolean(this.leg.waypoint) || (Boolean(this.leg.waypoint) && !Boolean(this.leg.waypoint.id))) && Boolean(this.leg.waypoint_ident));
      this.waypointNonViable = !this.leg.hasWaypoint && Boolean(this.leg.waypoint_ident)
      this.airWayNonViable = (!Boolean(this.leg.airway) && Boolean(this.leg.airway_ident));
      this.checkLegValidity();
    },
    checkLegValidity: function() {
      this.leg.nonViable = (Boolean(this.waypointNonViable) || Boolean(this.airWayNonViable));
    }
  },
  components: {
    DisabledClearable
  }
};
</script>