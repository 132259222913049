import ApiService from "./api.service"

export default class TerminalsService extends ApiService
{
    path = 'terminals';
    transitions(terminalId) {
        return this.http.get(`${this.url}/${ terminalId }/transitions`);
    }

    iacs(params) {
        return this.http.get(`${this.url}/iacs`, { params: params } );
    }
}