<template>
    <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
            <p class="modal-card-title">Routes</p>
        </header>
        <section class="modal-card-body">
            <div class="columns">
                <div class="column">
                    <table class="table is-fullwidth">
                        <thead>
                            <tr>
                                <th width="40">Id</th>
                                <th>Public</th>
                                <th>Departure</th>
                                <th>Sid</th>
                                <th>Sid Transition</th>
                                <th>Star</th>
                                <th>Star Transition</th>
                                <th>Destination</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="route in routes" :key="route.id">
                                <td>{{ route.id }}</td>
                                <td>{{ route.is_public ? 'YES' : 'NOT' }}</td>
                                <td>{{ route.departure.name }}</td>
                                <td>{{ route.sid ? route.sid.name : '' }}</td>
                                <td>{{ route.sidTransition }}</td>
                                <td>{{ route.star ? route.star.name : '' }}</td>
                                <td>{{ route.starTransition }}</td>
                                <td>{{ route.destination.name }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>    
        </section>
        <footer class="modal-card-foot modal-foot-right">
            <button type="button" class="button" @click="$parent.close()">
                <span class="has-text-weight-bold is-uppercase">Close</span>
            </button>
        </footer>
    </div>
</template>
<script>
export default {
    name: 'RouteDuplicatesModal',
    props: [
        'routes'
    ]
}
</script>